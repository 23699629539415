import React from 'react';
import ReactDOM from 'react-dom';
import ImageCapture from './src/ImageCapture';
import CameraCapture from './src/CameraCapture';

let init = null;

export default {
	CameraCapture: {
		call: (callback) => {
			return {
				render: () => {
					ReactDOM.render(
						<CameraCapture callback={callback} />,
						document.querySelector('#skycop-can')
					);
				},
				unmount() {
					ReactDOM.unmountComponentAtNode(
						document.querySelector(init.selector)
					);
				},
			};
		},
	},
	ImageCapture: {
		call: (callback, binaryImage) => {
			return {
				render: () => {
					ReactDOM.render(
						<ImageCapture callback={callback} binaryImage={binaryImage} />,
						document.querySelector('#skycop-can')
					);
				},
				unmount() {
					ReactDOM.unmountComponentAtNode(
						document.querySelector(init.selector)
					);
				},
			};
		},
	},
};
