import React from 'react';
import { EnumCapturedResultItemType } from 'dynamsoft-core';
import { CameraEnhancer, CameraView } from 'dynamsoft-camera-enhancer';
import {
	CapturedResultReceiver,
	CaptureVisionRouter,
} from 'dynamsoft-capture-vision-router';
import { MultiFrameResultCrossFilter } from 'dynamsoft-utility';
import './CameraCapture.css';
import { ERRORS } from './constants';
import '../cvr';
import { axiosInstance } from './Configs/axios';
import { Routes } from './Configs/routes';

class CameraCapture extends React.Component {
	pInit = null;
	pDestroy = null;

	uiContainer = React.createRef();

	initCameraView = async () => {
		const cameraView = await CameraView.createInstance(
			document.getElementsByClassName('wrapper')[0]
		);
		const cameraEnhancer = await CameraEnhancer.createInstance(cameraView);

		return { cameraView, cameraEnhancer };
	};

	async init() {
		const parentElement = document.getElementById('skycop-can');
		const { callback } = this.props;
		if (!callback) return callback({ error: ERRORS.NO_CALLBACK });

		try {
			const { cameraView, cameraEnhancer } = await this.initCameraView();

			if (this.uiContainer.current && this.uiContainer.current.innerText) {
				this.uiContainer.current.innerText = '';
			}
			if (this.uiContainer.current) {
				this.uiContainer.current = cameraView.getUIElement();
			}
			const router = await CaptureVisionRouter.createInstance();
			router.setInput(cameraEnhancer);

			const resultReceiver = new CapturedResultReceiver();
			resultReceiver.onDecodedBarcodesReceived = async (result) => {
				cameraEnhancer.close();
				if (parentElement) {
					parentElement.classList.add('hide_element');
					parentElement.classList.remove('parent');
				}

				if (!result.barcodeResultItems.length) {
					return callback({ error: ERRORS.NO_BARCODE });
				}

				if (result.barcodeResultItems && result.barcodeResultItems[0]) {
					await this.getDecodedValues(
						result.barcodeResultItems[0].text,
						callback
					);
				}
			};
			router.addResultReceiver(resultReceiver);

			const filter = new MultiFrameResultCrossFilter();
			filter.enableResultCrossVerification(
				EnumCapturedResultItemType.CRIT_BARCODE,
				true
			);

			filter.enableResultDeduplication(
				EnumCapturedResultItemType.CRIT_BARCODE,
				true
			);
			filter.setDuplicateForgetTime(
				EnumCapturedResultItemType.CRIT_BARCODE,
				3000
			);
			await router.addResultFilter(filter);
			if (parentElement) {
				parentElement.classList.remove('hide_element');
				parentElement.classList.add('parent');
			}
			await cameraEnhancer.open();
			await router.startCapturing('ReadSingleBarcode');

			return {
				cameraView,
				cameraEnhancer,
				router,
			};
		} catch (ex) {
			let errMsg = ex.message || ex;
			console.error(errMsg);
			if (parentElement) {
				parentElement.classList.add('hide_element');
				parentElement.classList.remove('parent');
			}
			const dce = document.getElementsByTagName('dce-component')
			if(dce && dce[0]){
				dce[0].remove();
			}
			throw ex;
		}
	}

	async destroy() {
		if (this.pInit) {
			const { cameraView, cameraEnhancer, router } = await this.pInit;
			router.dispose();
			cameraEnhancer.dispose();
			cameraView.dispose();
		}
	}

	async componentDidUpdate() {
		if (this.pInit) {
			const parentElement = document.getElementById('skycop-can');
			this.pInit.cameraEnhancer.open();
			parentElement.classList.remove('hide_element');
			parentElement.classList.add('parent');
		}
		if(this.pInit === null) {
			const parentElement = document.getElementById('skycop-can');
			if (parentElement) {
				parentElement.classList.remove('hide_element');
				parentElement.classList.add('parent');
			}
			this.pInit = await this.init();
		}
	}

	async componentDidMount() {
		if (this.pDestroy) {
			await this.pDestroy;
			this.pInit = await this.init();
		} else {
			this.pInit = await this.init();
		}
	}

	async componentWillUnmount() {
		await (this.pDestroy = this.destroy());
	}

	async shouldComponentUpdate() {
		return false;
	}

	getDecodedValues = async (parsedString, callback) => {
		await axiosInstance
			.post(Routes.decodeScan, { data: btoa(parsedString) })
			.then(({ data }) => {
				callback({ data });
			})
			.catch((e) => {
				callback({ error: ERRORS.DECODE_ERROR, stacktrace: e });
				console.error(e);
			});
	};

	closeCameraButton = () => {
		this.pInit && this.pInit.cameraEnhancer.close();
		const parentElement = document.getElementById('skycop-can');
		parentElement.classList.add('hide_element');
		parentElement.classList.remove('parent');
	};

	render() {
		return (
			<div className="wrapper">
				<div className="dce-video-container  video_height"></div>
				<div className="div-camera-capture" ref={this.uiContainer}></div>
				<a
					href="#"
					onClick={() => this.closeCameraButton()}
					className="closeScan"
				></a>
			</div>
		);
	}
}

export default CameraCapture;
