import '../cvr';
import React from 'react';
import { CaptureVisionRouter } from 'dynamsoft-capture-vision-router';
import { ERRORS } from './constants';
import { axiosInstance } from './Configs/axios';
import { Routes } from './Configs/routes';
class ImageCapture extends React.Component {
	pInit = null;
	pDestroy = null;

	async init() {
		const router = await CaptureVisionRouter.createInstance();
		return router;
	}

	async destroy() {
		if (this.pInit) {
			const router = await this.pInit;
			router.dispose();
		}
	}

	decodeImg = async (callback, binaryImage) => {
		if (!callback) return callback({ error: ERRORS.NO_CALLBACK });
		if (!binaryImage) return callback({ error: ERRORS.NO_IMAGE });
		try {
			const router = await this.pInit;

			if ((await router) && (await router.capture)) {
				const result = await router.capture(
					binaryImage,
					'ReadBarcodes_SpeedFirst'
				);

				if (result.items && result.items[0]) {
					await this.getDecodedValues(result.items[0].text, callback);
				}

				if (!result.items.length) callback({ error: ERRORS.NO_BARCODE });
			}
		} catch (ex) {
			let errMsg = ex.message || ex;
			console.error(errMsg);
			callback({ error: ERRORS.DECODE_ERROR, stacktrace: errMsg });
		}
	};

	getDecodedValues = async (parsedString, callback) => {
		await axiosInstance
			.post(Routes.decodeScan, { data: btoa(parsedString) })
			.then(({ data }) => {
				callback({ data });
			})
			.catch((e) => {
				callback({ error: ERRORS.DECODE_ERROR, stacktrace: e });
				console.error(e);
			});
	};

	async componentDidMount() {
		const { callback, binaryImage } = this.props;
		if (this.pDestroy) {
			await this.pDestroy;
			this.pInit = this.init();
		} else {
			this.pInit = this.init();
		}
		await this.decodeImg(callback, binaryImage);
	}

	componentWillUpdate() {
		const { callback, binaryImage } = this.props;

		if (!!callback && !!binaryImage) this.decodeImg(callback, binaryImage);
	}

	async componentWillUnmount() {
		await (this.pDestroy = this.destroy());
	}

	render() {
		return <div className="div-image-capture"></div>;
	}
}

export default ImageCapture;
