import axios from "axios"; 

export const axiosInstance = axios.create({
  baseURL : process.env.AXIOS_BASE_URL,
  headers: {
//  Authorization: `<Auth Token>`,
    'Content-Type': "application/json",
    timeout : 1000,
  }, 
});
